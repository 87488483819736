// Models

export enum Tags {
  PEP = 1,
  SANCTIONED = 2,
  NO_RECORD = 3,
  HIGH_RISK = 4,
  HELLO_SODA_CHECK_ERROR = 5,
  RISK_MONITORING_ERROR = 6,
  MONITORED = 7,
  MEDIUM_RISK = 8,
  SOF_REQUIRED = 9, // Source of Funds Required
  SOF_VERIFIED = 10, // Source of Funds Approved
  POI_REQUIRED = 11, // Proof of Identity Required
  POI_VERIFIED = 12, // Proof of Idenitry Approved
  POA_REQUIRED = 13, // Proof of Address Required
  POA_VERIFIED = 14, // Proof of Address Approved
  POF_REQUIRED = 15, // Proof of Funds Required
  POF_VERIFIED = 16, // Proof of Funds Approved
  BLOCKED_DEPOSIT = 17,
  VERIFIED = 20, // Account is fully KYC verified
  UNVERIFIED = 21, // Account is not fully KYC verified
  SEON_CHECK_ERROR = 22,
  SPELPAUS_MARKETING_BLOCKED = 23,
  SPELPAUS_LOGIN_BLOCKED = 24,
  AGE_UNDER_25 = 25,
  LIMITS_ALLOWING_LOGIN = 26,
}

export const KYC_TAGS = [
  Tags.SOF_REQUIRED,
  Tags.SOF_VERIFIED,
  Tags.POI_REQUIRED,
  Tags.POI_VERIFIED,
  Tags.POA_REQUIRED,
  Tags.POA_VERIFIED,
  Tags.POF_REQUIRED,
  Tags.POF_VERIFIED,
];

const TagsColors: { [key: string]: { color: string; tags: Tags[] } } = {
  BLUE: {
    color: "primary",
    tags: [],
  },
  GREEN: {
    color: "success",
    tags: [
      Tags.NO_RECORD,
      Tags.MONITORED,
      Tags.SOF_VERIFIED,
      Tags.POA_VERIFIED,
      Tags.POI_VERIFIED,
      Tags.POF_VERIFIED,
    ],
  },
  RED: {
    color: "danger",
    tags: [
      Tags.HIGH_RISK,
      Tags.HELLO_SODA_CHECK_ERROR,
      Tags.RISK_MONITORING_ERROR,
      Tags.SPELPAUS_MARKETING_BLOCKED,
      Tags.SPELPAUS_LOGIN_BLOCKED,
    ],
  },
  YELLOW: {
    color: "warning",
    tags: [Tags.BLOCKED_DEPOSIT],
  },
  ORANGE: {
    color: "orange",
    tags: [
      Tags.SANCTIONED,
      Tags.PEP,
      Tags.MEDIUM_RISK,
      Tags.SOF_REQUIRED,
      Tags.POA_REQUIRED,
      Tags.POI_REQUIRED,
      Tags.POF_REQUIRED,
    ],
  },
};

export const getTagColor = (tag: Tags): string => {
  const colorKey = Object.keys(TagsColors).find((key: string) => {
    return TagsColors[key].tags.includes(tag);
  });

  return colorKey ? TagsColors[colorKey].color : "primary";
};

export class Tag {
  id: number;
  name: string;
  description: string;
}
