/**
    //////////////////////////////////////////////////////////////////////
    THIS FILE WAS GENEREATED
    Run 'npm run export-models' on backend to sync models
    ((DO NOT MODIFIY!!!))
    //////////////////////////////////////////////////////////////////////
    */
/* -------------------------------- Models -------------------------------- */
import { BackOfficeAccount } from "./BackOfficeAccount";

export enum RoleName {
  Admin = "Admin",
  Developer = "Developer",
  CustomerSupport = "CustomerSupport",
}

export enum RoleAccessLevel {
  PaymentAdministrator = 4,
  Admin = 3,
  Developer = 2,
  CustomerSupport = 1,
}

export class Role {
  id: number;

  name: string;

  accessLevel: number;

  account: BackOfficeAccount;
}
