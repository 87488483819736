import React, { FunctionComponent, useState } from "react";
import { Card, Table, Modal, Button } from "react-bootstrap";
import { IStatusLogsProps } from "../AccountLinks/AccountLinks";
import ManualPayment from "./ManualPayment";
import ManualWithdraw from "./ManualWithdraw";
import { makeRequest } from "../../../utilities/axio.helper";
import urls from "../../../utilities/urls";
import { CreateManualTransactionDto } from "../../../models/dto/transaction.dto";
import toastr from "toastr";

const ManualBalanceAdjustment: FunctionComponent<IStatusLogsProps> = ({
  account,
}) => {
  const [transactionData, setTransactionData] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);

  const handleManualTransactionSubmit = async (data: any) => {
    try {
      let addedAmountToWagering = null;
      let removedAmountFromWagering = null;

      if (data.type === "CREDIT") {
        addedAmountToWagering = data.modifyWagering
          ? parseFloat(data.amount)
          : null;
      } else if (data.type === "WITHDRAW") {
        removedAmountFromWagering = data.modifyWagering
          ? parseFloat(data.amount)
          : null;
      }

      const paymentPayload: CreateManualTransactionDto = {
        type: data.type,
        amount: parseFloat(data.amount),
        addedAmountToWagering,
        removedAmountFromWagering,
        comment: data.comments,
        accountId: account.id,
        withdrawalFee: +data.withdrawFee,
      };

      setTransactionData(paymentPayload);
      setShowModal(true);
    } catch (e) {
      toastr.error((e as any).message);
    }
  };

  const handleSendRequest = async () => {
    try {
      await makeRequest<CreateManualTransactionDto>(
        "post",
        urls.MANUAL_TRANSACTIONS,
        transactionData,
      );
      setShowModal(false);
      toastr.success(`Manual transaction for Account ${account.name}`);
    } catch (e) {
      toastr.error((e as any).message);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <h2 style={{ marginLeft: "2rem" }}>Manual Balance Adjustment</h2>
      <Card className="m-4">
        <Card.Body>
          <Table responsive striped bordered hover className="mt-5">
            <thead>
              <th>{`Current -- Account Balance ${
                account.balance ? account.balance : 0
              } / Wagering ${
                account.wagering ? account.wagering.withdrawWager : 0
              }`}</th>
              <tr>
                <th>{"Manual Payment (Credit)"}</th>
                <th>{"Manual Withdraw (Debit)"}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <ManualPayment
                    onSubmit={handleManualTransactionSubmit}
                    account={account}
                  />
                </td>
                <td>
                  <ManualWithdraw
                    onSubmit={handleManualTransactionSubmit}
                    account={account}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Transaction type</h5>
          <p>{transactionData?.type || ""}</p>
          <h5>Account name</h5>
          <p>{account.name || ""}</p>
          <h5>Amount</h5>
          <p>{transactionData?.amount || ""}</p>
          {transactionData?.addedAmountToWagering !== null && (
            <>
              <h5>Added Amount To Wagering</h5>
              <p>{transactionData?.addedAmountToWagering}</p>
            </>
          )}
          {transactionData?.removedAmountFromWagering !== null && (
            <>
              <h5>Removed Amount From Wagering</h5>
              <p>{transactionData?.removedAmountFromWagering}</p>
            </>
          )}
          {!isNaN(transactionData?.withdrawalFee) && (
            <>
              <h5>Withdrawal Fee</h5>
              <p>{transactionData?.withdrawalFee}</p>
            </>
          )}
          <h5>Comment</h5>
          <p>{transactionData?.comment || ""}</p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="danger" onClick={handleCloseModal}>
            Back
          </Button>
          <Button variant="primary" onClick={handleSendRequest}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManualBalanceAdjustment;
