import DayJS from "dayjs";
const EMPTY_DATE_PLACEHOLDER = "";

export const safeFormatDate = (date?: Date): string => {
  if (date === null) {
    return EMPTY_DATE_PLACEHOLDER;
  }
  return DayJS(date).format("YYYY-MM-DD HH:mm:ss");
};

export const safeFormatStringToDate = (date?: string): Date => {
  return DayJS(date).toDate();
};
