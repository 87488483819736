/**

 //////////////////////////////////////////////////////////////////////

 THIS FILE WAS GENEREATED

 Run 'npm run export-models' on backend to sync models

 ((DO NOT MODIFIY!!!))

 //////////////////////////////////////////////////////////////////////

 */

/* -------------------------------- Models -------------------------------- */

import { Account } from "./Account";

export class DepositLimit {
  accountId: number;

  typeId: number;

  amount: number;

  remaining: number;

  increase: number;

  account: Account;

  nextReset: Date;

  lastReset: Date;

  nextIncrease: Date;

  createdAt: Date;

  updatedAt: Date;

  deletedAt: Date;
}
