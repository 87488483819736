import React, { FunctionComponent, useState, useEffect } from "react";
import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";
import { Card, Table } from "react-bootstrap";

import { BackofficeAccountsListItem } from "./BackofficeAccountListItem";
import { BackOfficeAccount } from "src/models/admin/BackOfficeAccount";
import { Role } from "src/models/admin/Role";
import config from "src/config";

const BackofficeAccountsList: FunctionComponent<any> = () => {
  const [backofficeAccounts, setBackofficeAccounts] = useState<
    BackOfficeAccount[]
  >();
  const [roles, setRoles] = useState<Role[]>([]);

  useEffect(() => {
    async function getBackofficeAccounts() {
      const roles = await makeRequest<Role[]>(
        "post",
        urls.BACK_OFFICE_ACCOUNT_ROLE_LIST,
        {
          adminPassword: config.BACKOFFICE_ADMIN_PASSWORD,
        },
      );
      setRoles(roles);

      const response = await makeRequest<BackOfficeAccount[]>(
        "post",
        urls.BACK_OFFICE_ACCOUNT_LIST,
        {
          adminPassword: config.BACKOFFICE_ADMIN_PASSWORD,
        },
      );
      setBackofficeAccounts(response);
    }
    getBackofficeAccounts();
  }, []);

  return (
    <>
      <Card>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Active</th>
                <th>Roles</th>
              </tr>
            </thead>
            <tbody>
              {backofficeAccounts &&
                backofficeAccounts.map((account: BackOfficeAccount) => {
                  return (
                    <BackofficeAccountsListItem
                      key={account.id}
                      account={account}
                      roles={roles}
                    />
                  );
                })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default BackofficeAccountsList;
