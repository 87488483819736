/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Jumbotron,
  Row,
  Table,
} from "react-bootstrap";
import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";
import { Account } from "src/models/app/Account";

interface IKPIProps {
  account: Account;
}

interface KPI {
  depositCount: number;
  depositAmount: number;
  withdrawCount: number;
  withdrawAmount: number;
  spinCount: number;
  spinAmount: number;
  winCount: number;
  winAmount: number;
  netCount: number;
  netAmount: number;
}

class KPIDetails {
  monthlyIncome: number;
}

const KPI: FunctionComponent<IKPIProps> = (props: IKPIProps) => {
  const [kpi, setKpi] = useState<KPI>();
  const [kpiDetails, setKpiDetails] = useState<KPIDetails>(new KPIDetails());
  const [getDataStatus, setGetDataStatus] = useState(false);

  const getDataView = () => {
    return (
      <>
        <Card className="m-4">
          <Card.Body className="text-center">
            <Button variant="primary" onClick={onClickGetDataButton}>
              Get Data
            </Button>
          </Card.Body>
        </Card>
      </>
    );
  };

  const onClickGetDataButton = () => {
    setGetDataStatus(true);
  };

  const fetchKPIData = async (): Promise<void> => {
    const response = await makeRequest<KPI>("post", urls.KPI, {
      accountId: props.account.id,
    });

    setKpi(response);
  };

  const fetchKPIDetailsData = async (): Promise<void> => {
    const response = await makeRequest<KPIDetails>(
      "get",
      `${urls.KPI_DETAILS}/${props.account.id}`,
    );

    setKpiDetails(response);
  };

  useEffect(() => {
    if (getDataStatus) {
      fetchKPIData();
      fetchKPIDetailsData();
    }
  }, [getDataStatus]);

  const getKPITable = () => {
    return (
      <>
        <h2 style={{ marginLeft: "2rem" }}>
          KPI of Account For The Last 3 Months
        </h2>
        <Card className="m-4">
          <Card.Body>
            {" "}
            <Table responsive striped bordered hover className="mt-5">
              <thead>
                <tr>
                  <th>{"Type"}</th>
                  <th>{"Total"}</th>
                  <th>{"Amount in KR"}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Deposits</td>
                  <td>{kpi?.depositCount}</td>
                  <td>{kpi?.depositAmount} kr</td>
                </tr>
                <tr>
                  <td>Withdrawals</td>
                  <td>{kpi?.withdrawCount}</td>
                  <td>{kpi?.withdrawAmount} kr</td>
                </tr>
                <tr>
                  <td>Wagers</td>
                  <td>{kpi?.spinCount}</td>
                  <td>{kpi?.spinAmount} kr</td>
                </tr>
                <tr>
                  <td>Wins</td>
                  <td>{kpi?.winCount}</td>
                  <td>{kpi?.winAmount} kr</td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr style={{ fontWeight: "bold", fontSize: "18px" }}>
                  <td>Net</td>
                  <td></td>
                  <td>{kpi?.netAmount} kr</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </>
    );
  };

  const getKPIDetailsForm = () => {
    return (
      <>
        <h2 style={{ marginLeft: "2rem" }}>Monthly Income</h2>
        <Card className="m-4">
          <Card.Body>
            <>
              <Form.Group>
                <Row>
                  <Col>
                    <Form.Label>Monthly Income</Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <Form.Control
                      type="number"
                      placeholder="Monthly Income"
                      value={kpiDetails?.monthlyIncome}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ): void => {
                        const input = Number(event.target.value);
                        setKpiDetails({
                          ...kpiDetails,
                          monthlyIncome: input,
                        });
                      }}
                    ></Form.Control>
                  </Col>
                  <Col>
                    <Button variant="primary" onClick={onSaveKYCDetails}>
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form.Group>
            </>
          </Card.Body>
        </Card>
      </>
    );
  };

  const getKPIView = () => {
    return (
      <>
        {getKPITable()}
        {getKPIDetailsForm()}
      </>
    );
  };

  const onSaveKYCDetails = async (): Promise<void> => {
    try {
      if (!kpiDetails?.monthlyIncome) {
        toastr.error("Please enter a valid monthly income!");
        return;
      }

      await makeRequest<KPIDetails>("post", urls.KPI_DETAILS, {
        ...kpiDetails,
        accountId: props.account.id,
      });

      toastr.success("KPI Details saved!");
    } catch (err) {
      toastr.error((err as any).message);
    }
  };

  return (
    <>
      <Jumbotron className="m-4">
        {getDataStatus ? getKPIView() : getDataView()}
      </Jumbotron>
    </>
  );
};

export default KPI;
