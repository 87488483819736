/**
    //////////////////////////////////////////////////////////////////////
    THIS FILE WAS GENEREATED
    Run 'npm run export-models' on backend to sync models
    ((DO NOT MODIFIY!!!))
    //////////////////////////////////////////////////////////////////////
    */

import { Transaction } from "../app/Transaction";

export class GetAccountMonetaryTransactionsWhere {
  type?: string;

  status?: string;

  createdAt?: Date;
}
export class RequestGetAccountGameTransactionsDto {
  where?: GetAccountGameTransactionsWhere;

  accountId: number;

  limit?: number;

  offset?: number;
}
export class GetAccountGameTransactionsWhere {
  gameName?: string;

  roundId?: string;

  transactionId?: number;

  createdAt?: Date;
}

export class GetAccountTransactionsWhere {
  type?: string;

  status?: string;

  createdAt?: Date;
}

export class RequestGetAccountTransactionsDto {
  where?: GetAccountTransactionsWhere;

  accountId: number;

  limit?: number;

  offset?: number;
}
export class GetTransactionsWhere {
  id?: number[];

  accountId: number;

  type?: string[];

  withDeleted: boolean;

  gameName?: string[];
}

export class RequestGetTransactionsDto {
  where?: GetTransactionsWhere;

  accountId: number;

  limit?: number;

  offset?: number;
}

export class PaginatedTransaction {
  transactions: Transaction[];
  totalTransactions: number;
}
export class ResponseGetTransactionsDto {
  payment: PaginatedTransaction;

  casino: PaginatedTransaction;
}
export class RequestGetTransactionTotalsDto {
  accountId: number;
}

export class ResponseGetTransactionTotalsDto {
  totalWithdrawals: number;
  totalDeposit: number;
}

export class CreateManualTransactionDto {
  type: "CREDIT" | "WITHDRAWAL";

  amount: number;

  addedAmountToWagering?: number | null;

  removedAmountFromWagering?: number | null;

  comment: string;

  withdrawalFee?: number;

  accountId: number;
}

export class RequestGetPaymentAccountDto {
  accountId: number;
}
