import React, { FunctionComponent, useState } from "react";

import { Account } from "src/models/app/Account";
import { Tags } from "src/models/app/Tag";

import { Table, Button, Card } from "react-bootstrap";

import { DepositLimit } from "src/models/app/DepositLimit";

import { AccountDepositLimitItem } from "./AccountDepositLimitItem";

import { AccountDepositLimitUpdateModal } from "./AccountDepositLimitUpdateModal";
import { AccountDepositLimitAllowingLoginModal } from "./AccountDepositLimitAllowingLoginModal";
import { AccountDepositLimitCreateModal } from "./AccountDepositLimitCreateModal";

export interface IAccountDepositLimitsProps {
  account: Account;

  refreshAccount: () => void;
}

export const AccountDepositLimits: FunctionComponent<IAccountDepositLimitsProps> = (
  props: IAccountDepositLimitsProps,
) => {
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [
    showDepositLimitsAllowingLogin,
    setShowDepositLimitsAllowingLogin,
  ] = useState<boolean>(false);
  const [
    showCreateDepositLimitsModal,
    setShowCreateDepositLimitsModal,
  ] = useState<boolean>(false);

  const showUpgradeLimitModal = (): void => setShowUpgradeModal(true);
  const hideUpgradeLimitModal = (): void => setShowUpgradeModal(false);

  const showLimitsAllowingLoginModal = (): void =>
    setShowDepositLimitsAllowingLogin(true);
  const hideLimitsAllowingLoginModal = (): void =>
    setShowDepositLimitsAllowingLogin(false);

  const showCreateLimitsModal = (): void =>
    setShowCreateDepositLimitsModal(true);
  const hideCreateLimitsModal = (): void =>
    setShowCreateDepositLimitsModal(false);

  const hasLimitAllowingLoginTag = props.account.accountTags.some(
    (tag) => tag.tagId === Tags.LIMITS_ALLOWING_LOGIN,
  );

  const getUpdateDepositLimitModal = (): React.ReactChild => {
    if (showUpgradeModal && props.account) {
      return (
        <AccountDepositLimitUpdateModal
          account={props.account}
          removeModal={hideUpgradeLimitModal}
          onUpdated={onUpdatedLimit}
        />
      );
    }

    return <></>;
  };

  const getDepositLimitAllowingLoginModal = (): React.ReactChild => {
    if (showDepositLimitsAllowingLogin && props.account) {
      return (
        <AccountDepositLimitAllowingLoginModal
          account={props.account}
          removeModal={hideLimitsAllowingLoginModal}
        />
      );
    }

    return <></>;
  };

  const getCreateDepositLimitsModal = (): React.ReactChild => {
    if (showCreateDepositLimitsModal && props.account) {
      return (
        <AccountDepositLimitCreateModal
          account={props.account}
          removeModal={hideCreateLimitsModal}
          onSave={onUpdatedLimit}
        />
      );
    }

    return <></>;
  };

  const onUpdatedLimit = (): void => {
    props.refreshAccount();
  };

  return (
    <>
      <Card>
        <Card.Header>
          Account Deposit Limits
          {props.account.depositLimits.length === 0 ? (
            <Button
              className="float-right"
              style={{ marginLeft: "1em" }}
              variant="primary"
              onClick={showCreateLimitsModal}
            >
              Create Limits
            </Button>
          ) : (
            <Button
              className="float-right"
              variant="primary"
              onClick={showUpgradeLimitModal}
            >
              Update Limits
            </Button>
          )}
        </Card.Header>

        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Type</th>

                <th>Amount</th>

                <th>Remaining</th>

                <th>Last reset</th>

                <th>Next reset</th>

                <th>Increase</th>

                <th>Next increase</th>
              </tr>
            </thead>

            <tbody>
              {props.account.depositLimits.map((depositLimit: DepositLimit) => (
                <AccountDepositLimitItem
                  key={depositLimit.typeId}
                  depositLimit={depositLimit}
                  account={props.account}
                  onUpdated={onUpdatedLimit}
                />
              ))}
            </tbody>
          </Table>
        </Card.Body>
        {!hasLimitAllowingLoginTag && (
          <div className="text-center card-body">
            <Button variant="danger" onClick={showLimitsAllowingLoginModal}>
              Allow the user to make login/withdraw
            </Button>
          </div>
        )}
      </Card>
      {getUpdateDepositLimitModal()}
      {getDepositLimitAllowingLoginModal()}
      {getCreateDepositLimitsModal()}
    </>
  );
};
