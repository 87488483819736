import React, { FunctionComponent } from "react";
import { Account } from "src/models/app/Account";
import { Card, Table } from "react-bootstrap";
import urls from "../../../utilities/urls";

export interface IStatusLogsProps {
  account: Account;
  refreshAccount?: () => void;
  documentType?: string;
}

interface LinkData {
  name: string;
  path: string;
  baseUrl: string;
}

const linksData: LinkData[] = [
  {
    name: "Metabase profile",
    path: urls.METABASE_PROFILE,
    baseUrl: urls.METABASE_CLIENT_PROFILE,
  },
  {
    name: "Metabase journey",
    path: urls.METABASE_JOURNEY,
    baseUrl: urls.METABASE_CLIENT_PROFILE,
  },
];

const AccountLinks: FunctionComponent<IStatusLogsProps> = ({ account }) => {
  const generateMetabaseUrl = (link: LinkData) => {
    return `${link.baseUrl}${link.path}?brand=Gami&account_id=${account.id}`;
  };

  const renderLinks = () => {
    return linksData.map((link, index) => (
      <tr key={index}>
        <td>{link.name}</td>
        <td>
          <a
            href={generateMetabaseUrl(link)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {link.name}
          </a>
        </td>
      </tr>
    ));
  };

  return (
    <>
      <h2 style={{ marginLeft: "2rem" }}>Links</h2>
      <Card className="m-4">
        <Card.Body>
          <Table responsive striped bordered hover className="mt-5">
            <thead>
              <tr>
                <th>{"Name"}</th>
                <th>{"Link"}</th>
              </tr>
            </thead>
            <tbody>{renderLinks()}</tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default AccountLinks;
