import React, {
  FunctionComponent,
  useEffect,
  useState,
  useCallback,
} from "react";

import urls from "src/utilities/urls";
import { Table } from "react-bootstrap";
import { safeFormatDate } from "src/utilities/date";
import { makeRequest } from "src/utilities/axio.helper";
import { RequestGetPaymentAccountDto } from "src/models/dto/transaction.dto";

import { PaymentAccount } from "src/models/app/PaymentAccount";

interface PaymentMethod {
  accountId: number;
  getDataStatus: boolean;
}

interface Meta {
  bank: string;
  descriptor: string;
  lastDigits: string;
}

const PaymentAcc: FunctionComponent<PaymentMethod> = ({
  accountId,
  getDataStatus,
}) => {
  const [paymentAcc, setPaymentAcc] = useState<PaymentAccount[]>();

  const fetchData = useCallback(async (): Promise<void> => {
    const response = await makeRequest<
      PaymentAccount[],
      RequestGetPaymentAccountDto
    >("post", urls.ACCOUNT_PAYMENTS, {
      accountId: accountId,
    });

    setPaymentAcc(response);
  }, [accountId]);

  useEffect(() => {
    if (getDataStatus) {
      fetchData();
    }
  }, [getDataStatus, fetchData]);

  const parseMeta = (meta: any): Meta | null => {
    try {
      return typeof meta === "string" ? JSON.parse(meta) : meta;
    } catch (error) {
      return null;
    }
  };

  return (
    <>
      <Table striped bordered hover className="mt-5">
        <thead>
          <tr>
            <th>ID</th>
            <th>Bank</th>
            <th>Descriptor</th>
            <th>Last Digits</th>
            <th>Created At</th>
            <th>Updated At</th>
          </tr>
        </thead>
        <tbody>
          {paymentAcc &&
            paymentAcc.map((paymentAcc: PaymentAccount) => {
              const meta = parseMeta(paymentAcc.meta);

              return (
                <tr key={paymentAcc.id}>
                  <td>{paymentAcc.id}</td>
                  <td>{meta?.bank || "N/A"}</td>
                  <td>{meta?.descriptor || "N/A"}</td>
                  <td>{meta?.lastDigits || "N/A"}</td>
                  <td>{safeFormatDate(paymentAcc.createdAt)}</td>
                  <td>{safeFormatDate(paymentAcc.updatedAt)}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
};

export default PaymentAcc;
