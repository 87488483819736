/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";
import { Account } from "src/models/app/Account";

export interface IAccountDepositLimitAllowingLoginModal {
  account: Account;
  removeModal: () => void;
}

export const AccountDepositLimitAllowingLoginModal: FunctionComponent<IAccountDepositLimitAllowingLoginModal> = (
  props: IAccountDepositLimitAllowingLoginModal,
) => {
  const [userConfirmation, setUserConfirmation] = useState<boolean>(false);

  const onHide = (): void => props.removeModal();

  const onSave = async (): Promise<void> => {
    try {
      // Create Limit
      await makeRequest<void, { accountId: number }>(
        "post",
        urls.DEPOSIT_LIMITS_ALLOWING_LOGIN,
        {
          accountId: props.account.id,
        },
      );

      toastr.success("User updated!");

      onHide();
    } catch (err) {
      toastr.error((err as any).message);
    }
  };

  const renderModal = (): React.ReactChild => {
    return (
      <Modal show={true} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Allow the user to login/withdraw</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="DL">
              <Form.Label>
                The player has made an initial deposit that exceeds the deposit
                limit and is prevented from making login and withdrawals. By
                checking the box below, you grant the player the ability to
                login and request withdrawals.
              </Form.Label>
              <Form.Check // prettier-ignore
                type={"checkbox"}
                id={`default-checkbox`}
                label={`I confirm that I want to allow the user to withdraw`}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
                  if (evt.target) {
                    if (evt.target.checked) {
                      setUserConfirmation(true);
                    } else {
                      setUserConfirmation(false);
                    }
                  }
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              onSave();
            }}
            disabled={!userConfirmation}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return <>{renderModal()}</>;
};
