/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FunctionComponent,
  useState,
  useEffect,
  ChangeEvent,
} from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Limit } from "src/models/app/Limit";
import { RequestUpsertLimitDto } from "src/models/dto/limit.dto";
import { makeRequest } from "src/utilities/axio.helper";
import { Account } from "src/models/app/Account";
import { RequestUpdateCommentDto } from "src/models/dto/comment.dto";
import urls from "src/utilities/urls";
import { Comment, CommentAction, CommentType } from "src/models/app/Comment";
import Config from "src/config";
import MultiSelect from "react-multi-select-component";
import { Option } from "react-multi-select-component/dist/lib/interfaces";

export interface IAccountLimitCreateModal {
  account: Account;
  removeModal: () => void;
  onCommentUpdated: (comment: Comment) => void;

  onSave: (limit: Limit) => void;
}

export const AccountLimitCreateModal: FunctionComponent<IAccountLimitCreateModal> = (
  props: IAccountLimitCreateModal,
) => {
  const sessionLimit = [1, 2, 4, 8, 12, 24];

  const [newExcLimit, setNewExcLimit] = useState<Limit>(new Limit());
  const [newDailyLimit, setNewDailyLimit] = useState<Limit>(new Limit());
  const [newWeeklyLimit, setNewWeeklyLimit] = useState<Limit>(new Limit());
  const [newMonthlyLimit, setNewMonthlyLimit] = useState<Limit>(new Limit());
  const [newComment, setNewComment] = useState<string>("");
  const [limitTypes, setLimitTypes] = useState<string[]>([]);
  const [chosenType, setChosenType] = useState<string>();
  const [formValid, setFormValid] = useState<boolean>(false);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedTypesString, setSelectedTypesString] = useState<string>();
  const [selectedAction, setSelectedAction] = useState<string>();
  const exclusionDurations = Config.limits.session;

  useEffect(() => {
    const getData = async (): Promise<void> => {
      setNewExcLimit({
        ...newExcLimit,
        type: "EXCLUSION",
        account: props.account,
      });
      setNewDailyLimit({
        ...newDailyLimit,
        type: "SESSION",
        hours: 24,
        account: props.account,
      });
      setNewWeeklyLimit({
        ...newWeeklyLimit,
        type: "SESSION",
        hours: 168,
        account: props.account,
      });
      setNewMonthlyLimit({
        ...newMonthlyLimit,
        type: "SESSION",
        hours: 720,
        account: props.account,
      });

      if (props.account.limits.length === 0) {
        setLimitTypes(["EXCLUSION", "SESSION"]);
        setChosenType("SESSION");
      } else {
        setLimitTypes(["EXCLUSION"]);
        setChosenType("EXCLUSION");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      newDailyLimit.amount <= newDailyLimit.hours &&
      newDailyLimit.amount > 0 &&
      newWeeklyLimit.amount <= newWeeklyLimit.hours &&
      newWeeklyLimit.amount > 0 &&
      newWeeklyLimit.amount >= newDailyLimit.amount &&
      newMonthlyLimit.amount <= newMonthlyLimit.hours &&
      newMonthlyLimit.amount &&
      newMonthlyLimit.amount >= newWeeklyLimit.amount
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [newDailyLimit, newWeeklyLimit, newMonthlyLimit]);

  useEffect(() => {
    if (
      selectedTypes === undefined ||
      selectedAction === undefined ||
      newComment === ""
    ) {
      setFormValid(false);
    } else {
      setSelectedTypesString(selectedTypes.toString().replace(",", ", "));
      setFormValid(true);
    }
  }, [selectedAction, selectedTypes, newComment]);

  const onHide = (): void => props.removeModal();

  const formatHours = (hours: number): string => {
    if (hours < 24) {
      if (hours === 1) {
        return "1 Hour";
      }

      return hours + " Hours";
    }
    switch (hours) {
      case 24:
        return "1 Day";
      case 168:
        return "1 Week";
      case 720:
        return "1 Month";
      case 2160:
        return "3 Months";
      case 8760:
        return "1 Year";
      default:
        return "Unknown Value";
    }
  };

  const getDurations = (limitType: string): React.ReactChild[] => {
    let durations: number[];
    if (limitType !== "EXCLUSION") {
      durations = sessionLimit;
    } else {
      durations = exclusionDurations;
    }

    return durations.map(
      (value: number): React.ReactChild => {
        return (
          <option key={value} value={value}>
            {formatHours(value)}
          </option>
        );
      },
    );
  };

  const getTypes = (): React.ReactChild[] => {
    return limitTypes.map(
      (value: string): React.ReactChild => {
        return (
          <option key={value} value={value} selected={chosenType === value}>
            {value}
          </option>
        );
      },
    );
  };

  const onSave = async (limit: Limit): Promise<void> => {
    try {
      // Create Limit
      await makeRequest<Limit, RequestUpsertLimitDto>("post", urls.LIMITS, {
        limit: limit,
      });

      if (newComment !== "") {
        const comment: Comment = new Comment();
        comment.comment = newComment;
        comment.account = props.account;
        comment.type = selectedTypesString!;
        comment.action = selectedAction!;

        // Create Comment
        await makeRequest<Limit, RequestUpdateCommentDto>(
          "post",
          urls.COMMENTS,
          {
            comment: newComment,
            type: selectedTypesString,
            action: selectedAction,
            accountId: props.account.id,
          },
        );
        props.onCommentUpdated(comment);
      }

      toastr.success("Created Limit!");

      props.onSave(limit);
      onHide();
    } catch (err) {
      toastr.error(err.message);
    }
  };

  const handleSelectedAction = (e: ChangeEvent<HTMLSelectElement>) =>
    setSelectedAction(e.target.value);

  const renderModal = (): React.ReactChild => {
    return (
      <Modal show={true} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Create Limit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="limitType">
              <Form.Label>Limit Type</Form.Label>
              <Form.Control
                as="select"
                custom
                defaultValue={chosenType}
                onChange={(option: ChangeEvent<HTMLInputElement>): void => {
                  setChosenType(option.target.value);
                }}
              >
                {getTypes()}
              </Form.Control>
            </Form.Group>
            {chosenType === "SESSION" ? (
              <>
                <Form.Group controlId="amount">
                  <Form.Label>Daily Amount</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="Amount.."
                    onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                      setNewDailyLimit({
                        ...newDailyLimit,
                        amount: Number(e.target.value),
                      });
                    }}
                    isValid={
                      newDailyLimit.amount <= newDailyLimit.hours &&
                      newDailyLimit.amount > 0
                    }
                  />
                  <Form.Label>Weekly Amount</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="Amount.."
                    onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                      setNewWeeklyLimit({
                        ...newWeeklyLimit,
                        amount: Number(e.target.value),
                      });
                    }}
                    isValid={
                      newWeeklyLimit.amount <= newWeeklyLimit.hours &&
                      newWeeklyLimit.amount > newDailyLimit.amount
                    }
                  />
                  <Form.Label>Monthly Amount</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="Amount.."
                    onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                      setNewMonthlyLimit({
                        ...newMonthlyLimit,
                        amount: Number(e.target.value),
                      });
                    }}
                    isValid={
                      newMonthlyLimit.amount <= newMonthlyLimit.hours &&
                      newMonthlyLimit.amount > newWeeklyLimit.amount
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid value.
                  </Form.Control.Feedback>
                </Form.Group>
                <Modal.Footer>
                  <Button
                    disabled={!formValid}
                    variant="primary"
                    onClick={() => {
                      onSave(newDailyLimit);
                      onSave(newWeeklyLimit);
                      onSave(newMonthlyLimit);
                    }}
                  >
                    Create
                  </Button>
                </Modal.Footer>
              </>
            ) : (
              <></>
            )}
            {chosenType === "EXCLUSION" ? (
              <>
                <Form.Group controlId="hours">
                  <Form.Label>Limit Hours</Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    onChange={(option: ChangeEvent<HTMLInputElement>): void => {
                      setNewExcLimit({
                        ...newExcLimit,
                        hours: Number(option.target.value),
                      });
                    }}
                  >
                    {getDurations(newExcLimit.type)}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="limitComment">
                  <Form.Group style={{ display: "flex" }}>
                    <Form.Label style={{ width: "10%", textAlign: "right" }}>
                      Type:{" "}
                    </Form.Label>
                    <div style={{ marginLeft: "1em", width: "60%" }}>
                      <MultiSelect
                        value={selectedTypes.map(
                          (type: string): Option => {
                            return { value: type, label: type };
                          },
                        )}
                        options={Object.values(CommentType).map(
                          (action: string): Option => {
                            return { label: action, value: action };
                          },
                        )}
                        onChange={(options: Option[]): void => {
                          const selectedTypes = options.map(
                            (option): string => option.value,
                          );
                          setSelectedTypes(selectedTypes);
                        }}
                        labelledBy={"actions"}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group style={{ display: "flex" }}>
                    <Form.Label style={{ width: "10%", textAlign: "right" }}>
                      Action:{" "}
                    </Form.Label>
                    <select
                      style={{ marginLeft: "1em", width: "60%" }}
                      onChange={handleSelectedAction}
                      required
                    >
                      <option value="" disabled selected hidden>
                        Select Comment Action
                      </option>
                      {Object.values(CommentAction).map((value) => (
                        <option
                          key={value}
                          value={value}
                          selected={value === selectedAction}
                        >
                          {value}
                        </option>
                      ))}
                    </select>
                  </Form.Group>
                  <Form.Label>Comment</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                      setNewComment(e.target.value);
                    }}
                  />
                </Form.Group>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    onClick={() => onSave(newExcLimit)}
                    disabled={!formValid}
                  >
                    Create
                  </Button>
                </Modal.Footer>
              </>
            ) : (
              <></>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  return <>{renderModal()}</>;
};
