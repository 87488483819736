import React, { FunctionComponent, ReactNode } from "react";
import { Card, Container } from "react-bootstrap";

const DefaultContainerWrapper: FunctionComponent = ({
  children,
}: {
  children?: ReactNode;
}) => {
  return (
    <div className="d-flex justify-content-center pt-11">
      <Card
        style={{
          width: "36rem",
        }}
      >
        <Card.Body>
          <Container className="text-center pt-2">
            <h2>Casino Gami</h2>
            <h5>Back Office</h5>
          </Container>
          <Container className="text-center pt-2">{children}</Container>
        </Card.Body>
      </Card>
    </div>
  );
};

export default DefaultContainerWrapper;
