import React, { useState, useEffect, FunctionComponent } from "react";
import { Table, Jumbotron, Button, Modal } from "react-bootstrap";
import toastr from "toastr";
import { makeRequest } from "../../utilities/axio.helper";
import urls from "../../utilities/urls";
import { safeFormatDate } from "../../utilities/date";
import OverlayLoader from "src/components/overlay/Overlay";

interface Transaction {
  id: number;
  type: string;
  amount: number;
  addToWagering: number | null;
  removeWagering: number | null;
  comment: string;
  withdrawalFee: number | null;
  createdAt: Date;
  status: string;
  account: {
    id: number;
    name: string;
  };
}

const TransactionList: FunctionComponent = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [
    selectedTransaction,
    setSelectedTransaction,
  ] = useState<Transaction | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchTransactions = async () => {
    try {
      const response = await makeRequest("get", urls.MANUAL_TRANSACTIONS_LIST);
      setTransactions(response);
    } catch (error) {
      toastr.error((error as any).message);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, []);
  const handleApprove = (id: number) => {
    const selected = transactions.find((transaction) => transaction.id === id);
    setSelectedTransaction(selected || null);
    setShowModal(true);
  };

  const handleSendReject = async (id: number) => {
    setLoading(true);
    try {
      await makeRequest("post", urls.MANUAL_TRANSACTIONS_REJECT, {
        manualTransactionId: id,
      });
      toastr.success(`Transaction id  ${id} was rejected`);
      fetchTransactions();
    } catch (error) {
      toastr.error((error as any).message);
    } finally {
      setLoading(false);
    }
  };

  const handleSendApprove = async (id: number) => {
    setLoading(true);
    try {
      await makeRequest("post", urls.MANUAL_TRANSACTIONS_APPROVE, {
        manualTransactionId: id,
      });
      setShowModal(false);
      toastr.success(`Transaction id  ${id} was approved`);
      fetchTransactions();
    } catch (error) {
      toastr.error((error as any).message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <OverlayLoader isLoading={loading} />
      <Jumbotron className="m-4">
        {transactions && transactions.length > 0 && (
          <Table striped bordered hover className="transactionTable">
            <thead>
              <tr>
                <th>ID</th>
                <th>Player name</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Add to Wagering</th>
                <th>Remove Wagering</th>
                <th>Comment</th>
                <th>Withdrawal Fee</th>
                <th>Created At</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction) => (
                <tr key={transaction.id}>
                  <td>{transaction.id}</td>
                  <td>{transaction.account.name}</td>
                  <td>{transaction.type}</td>
                  <td>{transaction.amount}</td>
                  <td>
                    {transaction.addToWagering !== null
                      ? transaction.addToWagering
                      : ""}
                  </td>
                  <td>
                    {transaction.removeWagering !== null
                      ? transaction.removeWagering
                      : ""}
                  </td>
                  <td>{transaction.comment}</td>
                  <td>
                    {transaction.withdrawalFee !== null
                      ? transaction.withdrawalFee
                      : ""}
                  </td>
                  <td>{safeFormatDate(transaction.createdAt)}</td>
                  <td>{transaction.status}</td>
                  <td>
                    <div className="d-flex justify-content-center flex-column">
                      <Button
                        variant="success"
                        onClick={() => handleApprove(transaction.id)}
                        className="mb-2"
                      >
                        Approve
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => handleSendReject(transaction.id)}
                      >
                        Reject
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Jumbotron>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Transaction type</h5>
          <p>{selectedTransaction?.type || ""}</p>
          <h5>Account ID and Player name</h5>
          <p>
            {selectedTransaction?.account.id || ""},{" "}
            {selectedTransaction?.account.name}
          </p>
          <h5>Amount</h5>
          <p>{selectedTransaction?.amount || ""}</p>
          {selectedTransaction?.addToWagering !== null && (
            <>
              <h5>Added Amount To Wagering</h5>
              <p>{selectedTransaction?.addToWagering}</p>
            </>
          )}
          {selectedTransaction?.removeWagering !== null && (
            <>
              <h5>Removed Amount From Wagering</h5>
              <p>{selectedTransaction?.removeWagering}</p>
            </>
          )}
          {selectedTransaction?.withdrawalFee !== null && (
            <>
              <h5>Withdrawal Fee</h5>
              <p>{selectedTransaction?.withdrawalFee}</p>
            </>
          )}
          <h5>Comment</h5>
          <p>{selectedTransaction?.comment || ""}</p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="danger" onClick={handleCloseModal}>
            Back
          </Button>
          <Button
            variant="primary"
            onClick={() => handleSendApprove(selectedTransaction!.id)}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TransactionList;
