/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Table,
  Pagination,
  Form,
  Button,
  Jumbotron,
  Card,
  Accordion,
} from "react-bootstrap";
import { makeRequest } from "src/utilities/axio.helper";
import {
  GetAccountGameTransactionsWhere,
  RequestGetAccountGameTransactionsDto,
} from "src/models/dto/transaction.dto";
import urls from "src/utilities/urls";

import { Account } from "src/models/app/Account";
import { Transaction } from "src/models/app/Transaction";
import { safeFormatDate, safeFormatStringToDate } from "src/utilities/date";

interface IAccountTransactionsProps {
  account: Account;
}

const GameTransactions: FunctionComponent<IAccountTransactionsProps> = (
  props: IAccountTransactionsProps,
) => {
  const [transactionTotalsDto, setTransactionTotalsDto] = useState<number>();

  const [accountTransactions, setaccountTransactions] = useState<
    Transaction[]
  >();

  const [gameNameInput, setGameNameInput] = useState<string>("");
  const [roundIdInput, setRoundIdInput] = useState<string>("");
  const [transactionIdInput, setTransactionIdInput] = useState<string>("");
  const [createdAtInput, setCreatedAtInput] = useState<string>("");
  const [getDataStatus, setgetDataStatus] = useState(false);

  const getDataView = () => {
    return (
      <>
        <Card className="m-4">
          <Card.Body className="text-center">
            <Button variant="primary" onClick={onClickGetDataButton}>
              Get Data
            </Button>
          </Card.Body>
        </Card>
      </>
    );
  };

  const onClickGetDataButton = () => {
    setgetDataStatus(true);
  };

  const fetchData = async (
    where?: GetAccountGameTransactionsWhere,
  ): Promise<void> => {
    const response = await makeRequest<
      { gameTransactions: Transaction[]; totalGameTransactions: number },
      RequestGetAccountGameTransactionsDto
    >("post", urls.ACCOUNT_GAME_TRANSACTIONS, {
      where,
      accountId: props.account.id,
      limit: paginationShowAmount,
      offset: (paginationIndex - 1) * paginationShowAmount,
    });

    setaccountTransactions(response.gameTransactions);

    setTransactionTotalsDto(response.totalGameTransactions);
  };

  const [paginationIndex, setPaginationIndex] = useState<number>(1);
  const [paginationShowAmount] = useState<number>(5);

  const getPagination = (totalTransactions: number): React.ReactChild => {
    const filteredItems = [];
    let prevIndex = 0;
    let nextIndex = 10;
    const paginationTotalPages = Math.ceil(
      totalTransactions / paginationShowAmount,
    );

    const items = [];

    for (let number = 1; number <= paginationTotalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === paginationIndex}
          onClick={(): void => setPaginationIndex(number)}
        >
          {number}
        </Pagination.Item>,
      );
    }

    if (paginationIndex - 10 > 0) {
      prevIndex = paginationIndex - 10;
    }

    const triggeringIndex = Math.ceil(nextIndex / 2) + 1;

    if (paginationIndex > triggeringIndex) {
      nextIndex = paginationIndex + 4;
      prevIndex = paginationIndex - 6;
    }

    for (let index = prevIndex; index <= nextIndex; index++) {
      filteredItems.push(items[index]);
    }

    if (paginationTotalPages <= 1) {
      return <></>;
    }

    return (
      <Pagination className="mr-4 mt-5 mb-4 flex-wrap">
        <Pagination.First onClick={(): void => setPaginationIndex(1)} />
        <Pagination.Prev
          onClick={(): void =>
            setPaginationIndex(
              paginationIndex !== 1 ? paginationIndex - 1 : paginationIndex,
            )
          }
        />
        {filteredItems}
        <Pagination.Next
          onClick={(): void =>
            setPaginationIndex(
              paginationIndex !== items.length
                ? paginationIndex + 1
                : paginationIndex,
            )
          }
        />
        <Pagination.Last
          onClick={(): void => {
            setPaginationIndex(items.length);
          }}
        />
      </Pagination>
    );
  };

  const fillWhereAndGet = () => {
    const where: GetAccountGameTransactionsWhere = new GetAccountGameTransactionsWhere();
    where.roundId = roundIdInput;
    where.transactionId = parseInt(transactionIdInput);
    where.gameName = gameNameInput;
    where.createdAt = safeFormatStringToDate(createdAtInput);
    fetchData(where);
  };

  useEffect(() => {
    if (getDataStatus) {
      fillWhereAndGet();
    }
  }, [getDataStatus, paginationIndex]);

  useEffect(() => {
    if (getDataStatus) {
      if (
        transactionIdInput ||
        roundIdInput ||
        gameNameInput ||
        createdAtInput
      ) {
        setPaginationIndex(1);
      }
      fillWhereAndGet();
    }
  }, [gameNameInput, transactionIdInput, roundIdInput, createdAtInput]);

  const getTransactions = (
    transactions: Transaction[],
    totalTransactions: number,
  ) => {
    return (
      <>
        {getPagination(totalTransactions)}
        <Table responsive striped bordered hover className="mt-5">
          <thead>
            <tr>
              <th>#</th>
              <th>{"Created At"}</th>
              <th>{"Balance Before"}</th>
              <th>{"Balance After"}</th>
              <th>{"Credit (Skr)"}</th>
              <th>{"Debit (Skr)"}</th>
              <th>{"Status"}</th>
              <th>{"Result"}</th>
              <th>{"External ID"}</th>
              <th>{"Game ID"}</th>
              <th>{"Round ID"}</th>
              <th>{"Spin Type"}</th>
              <th>{"Game Name"}</th>
              <th>{"Provider Id"}</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction: Transaction) => {
              return (
                <tr key={transaction.id}>
                  <td>{transaction.id}</td>
                  <td>{safeFormatDate(transaction.createdAt)}</td>
                  <td>{transaction.balanceBefore}</td>
                  <td>{transaction.balanceAfter}</td>
                  <td>{transaction.credit}</td>
                  <td>{transaction.debit}</td>
                  <td>{transaction.status}</td>
                  <td>{transaction.gameTransaction?.result}</td>
                  <td>{transaction.gameTransaction?.externalId}</td>
                  <td>{transaction.gameTransaction?.gameId}</td>
                  <td>{transaction.gameTransaction?.roundId}</td>
                  <td>{transaction.gameTransaction?.spinType}</td>
                  <td>{transaction.gameTransaction?.game.name}</td>
                  <td>{transaction.gameTransaction?.game.providerId}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  };

  const gameNameSelect = () => {
    return (
      <>
        <Form.Group>
          <Form.Label>Filter by Game Name</Form.Label>
          <Form.Control
            placeholder="Game Name"
            value={gameNameInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              const input = String(event.target.value);
              setGameNameInput(input);
            }}
          ></Form.Control>
        </Form.Group>
      </>
    );
  };

  const roundIdSelect = () => {
    return (
      <>
        <Form.Group>
          <Form.Label>Filter by Round Id</Form.Label>
          <Form.Control
            placeholder="Round Id"
            value={roundIdInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              const input = String(event.target.value);
              setRoundIdInput(input);
            }}
          ></Form.Control>
        </Form.Group>
      </>
    );
  };

  const transactionIdSelect = () => {
    return (
      <>
        <Form.Group>
          <Form.Label>Filter by Transaction Id</Form.Label>
          <Form.Control
            placeholder="Transaction Id"
            value={transactionIdInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              const input = event.target.value;
              setTransactionIdInput(input);
            }}
          ></Form.Control>
        </Form.Group>
      </>
    );
  };

  const createdAtPicker = () => {
    return (
      <>
        <Form.Group>
          <Form.Label>Filter by Created At</Form.Label>
          <Form.Control
            type="date"
            value={createdAtInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              const input = String(event.target.value);
              setCreatedAtInput(input);
            }}
          ></Form.Control>
        </Form.Group>
      </>
    );
  };

  const transactionView = () => {
    return (
      <>
        {" "}
        <Card className="m-4">
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              Filter
            </Accordion.Toggle>
          </Card.Header>
          <Card.Body>
            <Form>
              {gameNameSelect()}
              {roundIdSelect()}
              {transactionIdSelect()}
              {createdAtPicker()}
              <Button
                variant="dark"
                className="ml-2"
                onClick={() => {
                  setGameNameInput("");
                  setRoundIdInput("");
                  setCreatedAtInput("");
                }}
              >
                Clear
              </Button>
            </Form>
            {transactionTotalsDto && (
              <>
                {getTransactions(
                  accountTransactions as Transaction[],
                  transactionTotalsDto,
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </>
    );
  };

  return (
    <>
      <Jumbotron className="m-4">
        {getDataStatus ? transactionView() : getDataView()}
      </Jumbotron>
    </>
  );
};

export default GameTransactions;
