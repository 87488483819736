import React, { FunctionComponent } from "react";
import { Route, Switch } from "react-router-dom";
import { Jumbotron } from "react-bootstrap";
import CommentsList from "./CommentsList.tsx/CommentsList";

const CommentsPage: FunctionComponent = () => {
  return (
    <div>
      <Switch>
        <Route path="/comments">
          <Jumbotron className="m-4">
            <h3>Comments</h3>
            <CommentsList />
          </Jumbotron>
        </Route>
      </Switch>
    </div>
  );
};

export default CommentsPage;
