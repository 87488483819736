/**
    //////////////////////////////////////////////////////////////////////
    THIS FILE WAS GENEREATED
    Run 'npm run export-models' on backend to sync models
    ((DO NOT MODIFIY!!!))
    //////////////////////////////////////////////////////////////////////
    */

/* -------------------------------- Models -------------------------------- */
import { GameTransaction } from "./GameTransaction";
import { Account } from "./Account";
import { TrustlyTransaction } from "./TrustlyTransaction";
import { ParentChildTransaction } from "./ParentChildTransaction";

export enum TransactionType {
  SPIN = "SPIN",
  DEPOSIT = "DEPOSIT",
  WITHDRAW = "WITHDRAW",
  REFUND = "REFUND",
  CORRECTION = "CORRECTION",
  CHARGE = "CHARGE",
}

export enum TransactionStatus {
  OPEN = "OPEN",
  PROCESSED = "PROCESSED",
  FAILED = "FAILED",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
  CANCELED = "CANCELED",
  CORRECTED = "CORRECTED",
}

export class Transaction {
  id: number;

  account: Account;

  type: TransactionType;

  status: TransactionStatus;

  balanceBefore: number;

  debit: number;

  credit: number;

  balanceAfter: number;

  createdAt: Date;

  updatedAt: Date;

  deletedAt: Date;

  gameTransaction?: GameTransaction;

  trustlyTransaction?: TrustlyTransaction;

  parentOf: ParentChildTransaction[];

  childOf: ParentChildTransaction[];
}
