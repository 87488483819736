import React, { FunctionComponent, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Category } from "src/models/app/Category";
import urls from "src/utilities/urls";
import { makeRequest } from "src/utilities/axio.helper";
import { RequestUpdateCategoryDto } from "src/models/dto/category.dto";

export interface IAccountLimitCreateModal {
  removeModal: () => void;
  onCreated: (category: Category) => void;
}

export const CategoryCreateModal: FunctionComponent<IAccountLimitCreateModal> = (
  props: IAccountLimitCreateModal,
) => {
  const [category, setCategory] = useState<Category>(new Category());
  const [isEvent, setIsEvent] = useState<boolean>(false);

  const onHide = (): void => props.removeModal();

  const onSave = async (): Promise<void> => {
    try {
      // Create Limit
      await makeRequest<Category, RequestUpdateCategoryDto>(
        "post",
        urls.CATEGORIES,
        {
          ...category,
        },
      );

      props.onCreated(category);
      toastr.success("Created Category!");

      onHide();
    } catch (err) {
      toastr.error(err.message);
    }
  };

  const renderModal = (): React.ReactChild => {
    return (
      <Modal show={true} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Create Limit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="name">
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                type="text"
                value={category.name}
                onChange={(e): void => {
                  setCategory({ ...category, name: e.target.value });
                }}
              />
            </Form.Group>
            <Form.Group controlId="code">
              <Form.Label>Category Code</Form.Label>
              <Form.Control
                type="text"
                value={category.code}
                onChange={(e): void => {
                  setCategory({ ...category, code: e.target.value });
                }}
              />
            </Form.Group>
          </Form>
          <Form.Group controlId="isEvent">
            <Form.Check
              label="Event"
              type="switch"
              checked={isEvent}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
                setIsEvent(evt.target.checked);
                setCategory({
                  ...category,
                  isEvent: evt.target.checked ? 1 : 0,
                });
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onSave}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return <>{renderModal()}</>;
};
