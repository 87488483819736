import React, { FunctionComponent } from "react";

import { Account } from "src/models/app/Account";

import { DepositLimit } from "src/models/app/DepositLimit";

import DayJS from "dayjs";
enum DepositLimitTypes {
  DAILY = 1,
  WEEKLY = 2,
  MONTHLY = 3,
}
export interface IAccountDepositLimitItemProps {
  account: Account;

  depositLimit: DepositLimit;

  onUpdated: (depositLimit: DepositLimit) => void;
}

export const AccountDepositLimitItem: FunctionComponent<IAccountDepositLimitItemProps> = (
  props: IAccountDepositLimitItemProps,
) => {
  return (
    <>
      <tr key={props.depositLimit.typeId}>
        <td>{DepositLimitTypes[props.depositLimit.typeId]}</td>

        <td>{props.depositLimit.amount}</td>

        <td>{props.depositLimit.remaining}</td>

        <td>
          {DayJS(props.depositLimit.lastReset).format("YYYY-MM-DD HH:mm:ss")}
        </td>

        <td>
          {DayJS(props.depositLimit.nextReset).format("YYYY-MM-DD HH:mm:ss")}
        </td>

        <td>{props.depositLimit.increase}</td>

        <td>
          {props.depositLimit.nextIncrease &&
            DayJS(props.depositLimit.nextIncrease).format(
              "YYYY-MM-DD HH:mm:ss",
            )}
        </td>
      </tr>
    </>
  );
};
