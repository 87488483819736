/**
    //////////////////////////////////////////////////////////////////////
    THIS FILE WAS GENEREATED
    Run 'npm run export-models' on backend to sync models
    ((DO NOT MODIFIY!!!))
    //////////////////////////////////////////////////////////////////////
    */

/* -------------------------------- Models -------------------------------- */
import { GameCategory } from "./GameCategory";
import { Provider } from "./Provider";
import { GameTransaction } from "./GameTransaction";
import { Category } from "./Category";

export class Game {
  id: number;

  name: string;

  rating: number;

  providerWeight: number;

  code: string;

  primaryCategory: Category;

  externalId: string;

  provider: Provider;

  providerId: number;

  active: number;

  rtp: number;

  releasedAt: Date;

  createdAt: Date;

  updatedAt: Date;

  deletedAt: Date;

  iframeURL?: string;

  gameCategories: GameCategory[];

  gameTransactions: GameTransaction[];
}
