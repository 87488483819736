/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import {
  AccountStatusType,
  AccountStatusChangeReason,
} from "src/models/app/AccountStatusLog";
import { Account } from "src/models/app/Account";

interface IAccountStatusCheckboxProps {
  account: Account;
  type: AccountStatusType;
  active: number;
  textShown: string;
  onChange?: (checkboxStatus: ICheckboxStatus) => Promise<void>;
}
export interface ICheckboxStatus {
  reason?: AccountStatusChangeReason;
  active: number;
}

export const AccountStatusCheckbox: FunctionComponent<IAccountStatusCheckboxProps> = (
  props: IAccountStatusCheckboxProps,
) => {
  const [checkboxStatus, setCheckboxStatus] = useState<ICheckboxStatus>({
    active: 1,
  });

  const getLabel = (): string => {
    return props.textShown;
  };

  useEffect(() => {
    if (checkboxStatus) {
      if (props.onChange) {
        props.onChange(checkboxStatus);
      }
    }
  }, [checkboxStatus]);

  const updateStatus = async (
    active: number,
    reason?: AccountStatusChangeReason,
  ): Promise<void> => {
    try {
      setCheckboxStatus({ active, reason });
    } catch (error) {
      toastr.error((error as any).message);
    }
  };

  useEffect(() => {
    setCheckboxStatus({ ...checkboxStatus, active: props.active });
  }, [props.account, props.active]);

  return (
    <Form.Check
      type="checkbox"
      label={getLabel()}
      checked={checkboxStatus.active === 1}
      onChange={async (
        evt: React.ChangeEvent<HTMLInputElement>,
      ): Promise<void> => {
        if (evt.target) {
          const active: number = evt.target.checked ? 1 : 0;
          if (active === 0) {
            await updateStatus(active, AccountStatusChangeReason.CLOSED);
          } else {
            await updateStatus(active);
          }
        }
      }}
    />
  );
};
