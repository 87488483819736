/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FunctionComponent,
  useEffect,
  useState,
  Component,
} from "react";
import { Game } from "../../../models/app/Game";
import { useParams } from "react-router-dom";
import { Button, Card, Col, Form, Tabs, Tab } from "react-bootstrap";
import GameCategorySelector from "../GameCategorySelector/GameCategorySelector";
import { GameCategory } from "../../../models/app/GameCategory";
import { Category } from "../../../models/app/Category";
import { Provider } from "../../../models/app/Provider";
import toastr from "toastr";
import urls from "../../../utilities/urls";
import { makeRequest } from "../../../utilities/axio.helper";
import {
  RequestGetGamesDto,
  ResponseGetGamesDto,
  UpdateGameDto,
} from "src/models/dto/game.dto";
import {
  ResponseGetCategoryDto,
  RequestGetCategoryDto,
} from "src/models/dto/category.dto";
import { GameCategoriesList } from "../GameCategoriesList/GameCategoriesList";
import Moment from "moment";

const GameEditPage: FunctionComponent = () => {
  const [game, setGame] = useState<Game>(new Game());
  const { gameId } = useParams<{ gameId: string }>();

  const [providers, setProviders] = useState<Provider[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [chosenCategories, setChosenCategories] = useState<Category[]>([]);

  const getGame = async (): Promise<void> => {
    try {
      const response: ResponseGetGamesDto = await makeRequest<
        ResponseGetGamesDto,
        RequestGetGamesDto
      >("post", `${urls.GAMES_LIST}`, {
        where: { id: [Number(gameId)] },
      });

      const game = response.games[0];

      setGame(game);

      const chosenCats: Category[] = game.gameCategories.map(
        (gameCategory: GameCategory): Category => gameCategory.category,
      );
      setChosenCategories(chosenCats);
    } catch (err) {
      toastr.error((err as Error).message);
    }
  };

  useEffect(() => {
    getGame();
  }, [gameId]);

  useEffect(() => {
    const getData = async (): Promise<void> => {
      try {
        const response = await makeRequest<
          ResponseGetCategoryDto,
          RequestGetCategoryDto
        >("post", urls.CATEGORIES_LIST);
        setCategories(response.categories);

        const providers = await makeRequest<Provider[], any>(
          "post",
          urls.PROVIDERS,
        );

        setProviders(providers);
      } catch (err) {
        toastr.error((err as Error).message);
      }
    };

    getData();
  }, []);

  const getProviderById = (providerId: number): Provider =>
    providers.filter((provider: Provider) => provider.id === providerId)[0];

  const checkData = () => {
    if (game.rtp) {
      if (game.rtp > 99.99) {
        toastr.error("RTP should be lesser than 99.99");
        return false;
      }
    }
    return true;
  };

  const saveGame = async (): Promise<void> => {
    if (checkData()) {
      const gameCategoriesIds: number[] = chosenCategories.map(
        (category: Category) => {
          return category.id;
        },
      );

      try {
        await makeRequest<Game, UpdateGameDto>("post", `${urls.GAMES_UPDATE}`, {
          id: game.id,
          active: game.active,
          code: game.code,
          name: game.name,
          rating: game.rating,
          providerWeight: game.providerWeight,
          releasedAt: game.releasedAt,
          gameCategorieIds: gameCategoriesIds,
          primaryCategoryId: game.primaryCategory.id,
          providerId: game.provider.id,
          rtp: game.rtp,
        });

        toastr.success("Game Updated!");
      } catch (err) {
        toastr.error((err as Error).message);
      }
    }
  };

  type GameCategoriesContentProps = {
    game: Game;
    refreshData: () => void;
  };

  class GameCategoriesContent extends Component<
    GameCategoriesContentProps,
    {}
  > {
    render(): React.ReactChild {
      if (!this.props.game) {
        return <></>;
      }
      return (
        <GameCategoriesList
          game={this.props.game}
          refreshData={this.props.refreshData}
        />
      );
    }
  }
  const values: number[] = [
    0,
    0.5,
    1,
    1.5,
    2,
    2.5,
    3,
    3.5,
    4,
    4.5,
    5,
    5.1,
    5.2,
    5.3,
    5.4,
    5.5,
    5.6,
    5.7,
    5.8,
    5.9,
    6,
  ];

  return (
    <Tabs defaultActiveKey="details" id="uncontrolled-tab-example">
      <Tab eventKey="details" title="Details">
        <Card>
          <Card.Header>
            {gameId === undefined ? "Create" : "Edit"} Game
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Row>
                <Form.Group as={Col} controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter game name"
                    value={game.name}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                    ): void => setGame({ ...game, name: event.target.value })}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGameCategories">
                  <Form.Label>Game Categories</Form.Label>
                  <GameCategorySelector
                    game={game}
                    onChangeCategories={(
                      categoryIdentifiers: Category[],
                    ): void => setChosenCategories(categoryIdentifiers)}
                    categories={categories}
                    chosenCategories={chosenCategories}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="formCode">
                  <Form.Label>Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter code"
                    value={game.code}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                    ): void =>
                      setGame({
                        ...game,
                        code: event.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formExternalId">
                  <Form.Label>External Id</Form.Label>
                  <Form.Control
                    readOnly
                    type="text"
                    placeholder="Enter external id"
                    value={game.externalId}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                    ): void =>
                      setGame({
                        ...game,
                        externalId: event.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="formGamePrimaryCode">
                  <Form.Label>Game Primary Category</Form.Label>
                  <GameCategorySelector
                    game={game}
                    categories={categories}
                    defaultValue={0}
                    onChangeCategories={(category: Category[]): void =>
                      setGame({ ...game, primaryCategory: category[0] })
                    }
                    chosenCategories={
                      game.primaryCategory === undefined
                        ? []
                        : [game.primaryCategory]
                    }
                    singleSelect={true}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="exampleForm.SelectCustom">
                  <Form.Label>Provider Id</Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    value={game.provider?.id}
                    onChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      if (evt.target) {
                        const newProviderId: number = parseInt(
                          evt.target.value,
                        );
                        setGame({
                          ...game,
                          provider: getProviderById(newProviderId),
                        });
                      }
                    }}
                  >
                    {providers.map((provider: Provider) => (
                      <option key={provider.id} value={provider.id}>
                        {provider.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="formCode">
                  <Form.Label>Rating</Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    value={game?.rating}
                    onChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      setGame({
                        ...game,
                        rating: Number(evt.target.value),
                      });
                    }}
                  >
                    {values.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className="text-base">Release Date</Form.Label>
                  <Form.Row>
                    <Form.Control
                      type="date"
                      placeholder="ex. 24/05/1997"
                      defaultValue={
                        game.releasedAt
                          ? Moment(game.releasedAt).format("YYYY-MM-DD")
                          : ""
                      }
                      onChange={(evt: any): void => {
                        if (evt.target) {
                          setGame({
                            ...game,
                            releasedAt: Moment(
                              evt.target.value,
                              "YYYY-MM-DD",
                            ).toDate(),
                          });
                        }
                      }}
                    />
                  </Form.Row>
                </Form.Group>
                <Form.Group as={Col} controlId="formRtp">
                  <Form.Label>Game RTP</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter RTP of the game"
                    value={game.rtp}
                    max={99.99}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      let val = Number(event.target.value);
                      if (val > 99.99) {
                        val = 99.99;
                      }
                      setGame({ ...game, rtp: val });
                    }}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="Active"
                    checked={game.active === 1}
                    onChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      if (evt.target) {
                        setGame({
                          ...game,
                          active: evt.target.checked ? 1 : 0,
                        });
                      }
                    }}
                  />
                </Form.Group>
              </Form.Row>

              <Button variant="primary" onClick={saveGame}>
                Save
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Tab>
      {game && game.id && (
        <Tab eventKey="categories" title="Game Categories">
          <GameCategoriesContent game={game} refreshData={getGame} />
        </Tab>
      )}
    </Tabs>
  );
};

export default GameEditPage;
